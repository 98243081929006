<template>
  <div>
    <AtomLogo />
    <MoleculeCard size="md">
      <MoleculeForm
        :validator="$v"
        :loading="submitLoading"
        :disabled="submitLoading"
        @submit="submitHandler"
      >
        <h2 class="card-title text-center mb-4">{{ $t('pages.password.reset.title') }}</h2>
        <p class="text-muted mb-4">{{ $t('password.reset.description') }}</p>
        <AtomInput
          v-model.trim="form.password"
          label="password.new"
          type="password"
          class="mb-3"
        />
        <AtomInput
          v-model.trim="form.passwordConfirm"
          label="password.confirm"
          type="password"
          class="mb-3"
        />
        <div class="form-footer">
          <AtomButton
            type="submit"
            color="primary"
            class="w-100"
            :disabled="$v.$anyError"
          >
            {{ $t('password.action.change') }}
          </AtomButton>
        </div>
      </MoleculeForm>
    </MoleculeCard>
  </div>
</template>

<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

import MoleculeCard from '@/components/MoleculeCard'
import MoleculeForm from '@/components/MoleculeForm'
import AtomInput from '@/components/AtomInput'
import AtomButton from '@/components/AtomButton'
import AtomLogo from '@/components/AtomLogo'

export default {
  title: vm => vm.$t('pages.password.reset.title'),

  components: {
    MoleculeCard,
    MoleculeForm,
    AtomInput,
    AtomButton,
    AtomLogo,
  },

  data: () => ({
    form: {
      password: '',
      passwordConfirm: '',
    },

    submitLoading: false,
  }),

  validations: {
    form: {
      password: { required, minLength: minLength(6) },
      passwordConfirm: { required, sameAsPassword: sameAs(function () { return this.form.password }) },
    },
  },

  async beforeCreate () {
    if (this.$route.query.mode !== 'resetPassword' || !this.$route.query.oobCode) {
      this.$router.replace('/login')
      return
    }

    try {
      await this.$store.dispatch('verifyPasswordResetCode', this.$route.query.oobCode)
    } catch (e) {
      this.$router.replace('/login')
    }
  },

  methods: {
    async submitHandler () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      const formData = {
        code: this.$route.query.oobCode,
        newPassword: this.form.password,
      }

      try {
        this.submitLoading = true
        await this.$store.dispatch('changePassword', formData)
        this.form.password = this.form.passwordConfirm = ''
        this.$notify({
          title: this.$t('action.success'),
          text: this.$t('password.reset.success.message'),
          type: 'success',
        })
        this.$router.replace('/login')
      } catch {} finally {
        this.submitLoading = false
      }
    }
  },
}
</script>
