<template>
  <div class="text-center mb-4">
    <router-link to="/login">
      <img
        src="../assets/logo.svg"
        height="64"
        class="navbar-brand-autodark"
        alt=""
      >
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'AtomLogo',
}
</script>
